@import '../../styles/theme';
.Responding--root {
  display: flex;
  max-width: 23rem;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(5px) pxToRem(17px);
}

.Responding--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0px 5px 0px 3px;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.Responding--iconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0px 5px 0px 3px;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}

.Responding--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(10px);
  background-color: $ctaBlue;
}

.Responding--confirmHeader {
  text-align: left;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  padding-bottom: 0.8rem;
  padding-left: pxToRem(3px);
}

.Responding--buttons {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  padding: pxToRem(5px) pxToRem(0px);
  color: $white;
}

.Responding--b {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
}

.Responding--b span {
  font-weight: bold;
}

.Responding--eyeMessage {
  padding: pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(55px);
}

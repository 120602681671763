@import '../../styles/theme';

.BurglarAlarmHeader--root {
  font-size: pxToRem(12px);
  max-height: pxToRem(155px);
  min-height: pxToRem(50px);
  height: pxToRem(50px);
  border-bottom: 1px solid $grey4;
}
.BurglarAlarmHeader--icon {
  color: $coral;
}
.BurglarAlarmHeader--header {
  text-align: left;
}
.BurglarAlarmHeader--address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70vw;
  max-width: 195px;
  white-space: nowrap;
}
.BurglarAlarmHeader--contacts {
  display: flex;
  height: $contactCircleSize;
  justify-content: flex-end;
  flex-grow: 2;
  position: relative;
  text-align: right;
  padding-right: pxToRem(10px);
}
.BurglarAlarmHeader--iconAlarmCanceled {
  height: pxToRem(24px);
  width: pxToRem(24px);
}
.BurglarAlarmHeader--iconAlarm {
  height: pxToRem(30px);
  width: pxToRem(22px);
  fill: $coral;
}

.BurglarAlarmHeader--verified {
  color: $coral;
}

.BurglarAlarmHeader--disarmed {
  color: $green;
}
.BurglarAlarmHeader--address {
  color: $grey2;
}

.BurglarAlarmHeader--morePeople {
  cursor: pointer;
}

.BurglarAlarmHeader--circleMultiply {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      right: ($i - 1) * ($contactCircleSize - 8);
      z-index: $i;
    }
  }
}

.BurglarAlarmHeader--textWrapper {
  font-size: pxToRem(10px);
}

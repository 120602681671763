@import '../../variables';
.root {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.content {
  background-color: $pink;
  border-radius: 0.6rem;
  max-width: 18rem;
}

.message {
  padding: 0.5rem 1rem;
  font-size: pxToRem(14px);
  overflow-wrap: break-word;
}

.background {
  background: $white;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  width: 40px;
  height: 40px;
}

@import '../../styles/theme';
.ShareVideo--root {
  display: flex;
  max-width: 23rem;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(5px) pxToRem(19px);
}
.ShareVideo--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0.4rem 0.4rem 0rem 0rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.ShareVideo--iconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0.4rem 0.4rem 0rem 0rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}

.ShareVideo--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(10px);
  background-color: $ctaBlue;
}
.ShareVideo--confirmHeader {
  text-align: left;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  padding-bottom: 0.8rem;
  padding-left: pxToRem(3px);
}
.ShareVideo--buttons {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  padding: pxToRem(5px) pxToRem(0px);
  color: $white;
}
.ShareVideo--b{
  font-weight: bold;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
}
.ShareVideo--eyeMessage {
  padding: pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(55px);
}

@import '../../styles/theme';
.TimeExtended--root {
  display: flex;
  justify-content: center;
  color: $grey1;
}

.TimeExtended--timeline {
  display: flex;
  justify-content: center;
  text-align: center;
}

.TimeExtended--time {
  display: flex;
}

.TimeExtended--message {
  line-height: 140%;
}

.TimeExtended--message span {
  font-weight: bold;
}

.TimeExtended--clock {
  line-height: normal;
  margin-right: 2px;
  font-size: 90%;
}

@import '../../styles/theme';
.AlarmConfirmation--root {
 padding: pxToRem(15px);
  .AlarmConfirmation--cancel, .AlarmConfirmation--verify {
    font-weight: bold;
    font-size: pxToRem(14px);
    line-height: pxToRem(17px);
    letter-spacing: pxToRem(1px);
    text-transform: uppercase;
    padding: pxToRem(10px) 0;
    color: $white;
  }
  .AlarmConfirmation--cancel[disabled] {
    color: $white;
  }
}


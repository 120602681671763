@import '../../styles/theme';
.Input--root {
  background-color: $grey5;
}

.Input--inputContainer {
  display: flex;
  align-items: center;
  border-radius: pxToRem(22px);
  justify-content: center;
  margin: pxToRem(13px) pxToRem(16px);
  margin-top: 0.1rem;
  min-height: 43px;
  padding-right: 4px;
  overflow-wrap: break-word;
  [contentEditable='true']:empty:not(:focus)::before {
    content: attr(data-ph);
  }
  border: 1px solid $grey4;
  background-color: $white;
}

.Input--inputContainer-resi {
  display: flex;
  align-items: center;
  border-radius: pxToRem(22px);
  justify-content: center;
  margin: pxToRem(13px) pxToRem(16px);
  min-height: 43px;
  padding-right: 4px;
  overflow-wrap: break-word;
  [contentEditable='true']:empty:not(:focus)::before {
    content: attr(data-ph);
  }
  border: 1px solid $grey4;
  background-color: $white;
}

.Input--inputFocused {
  border: 1px solid $grey2;
}

[data-ph] {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $grey6;
}

.Cantsee {
  text-align: center;
  line-height: 140%;
  font-weight: 700;
  color: #5e6366;
  font-family: Open Sans,sans-serif;
  font-style: normal;
}

.Input--textarea {
  width: calc(100% - 35px);
  text-align: left;
  padding: pxToRem(7px);
}

.Input--textarea:focus {
  outline: none;
}

.Input--button {
  align-self: flex-end;
  padding: pxToRem(4px);
  padding-right: 0;
  cursor: pointer;
  & svg {
    fill: $moonBlue;
  }
}

.Input--button :focus {
  outline: none;
}

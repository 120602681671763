@import '../../styles/theme';
.MessageEye--eye {
  display: flex;
  align-items: center;
  font-size: pxToRem(11px);
  line-height: pxToRem(15px);
}
.MessageEye--eyeIcon {
  margin-right: pxToRem(5px);
  height: pxToRem(9px);
  width: pxToRem(16px);
}

@import '../../styles/theme';

.NewMessages-root {
  background-color: $grey2;
  color: $white;
  font-size: pxToRem(10px);
  padding: pxToRem(9px) pxToRem(14px);
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  text-transform: uppercase;
}

@import '../../styles/theme';
.ServiceMessage--root {
  display: flex;
  max-width: 24rem;
  padding: pxToRem(15px) pxToRem(10px) pxToRem(5px) pxToRem(15px);
}

.ServiceMessage--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0px 5px 0px 3px;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.ServiceMessage--iconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0px 5px 0px 3px;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}

.ServiceMessage--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(7px) pxToRem(10px);
  background-color: $ctaBlue;
}

.ServiceMessage--confirmHeader {
  display: flex;
  align-items: center;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
}

.ServiceMessage--timeline {
  display: flex;
  justify-content: flex-start;
  margin-bottom: pxToRem(4px);
}

.ServiceMessage--time {
  font-size: 11px;
  line-height: 15px;
  padding-left: pxToRem(1px);
}

.ServiceMessage--messageLine {
  display: flex;
  flex-direction: column;
}


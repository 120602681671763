.theme-commercial {
  .BurglarAlarmHeader--root {
    background: $ADTBlue;
    border-bottom: 1px solid $moonBlue;
  }
  .BurglarAlarmHeader--iconAlarm {
    fill: $white;
  }
  .BurglarAlarmHeader--header {
    color: $white;
  }
  .BurglarAlarmHeader--verified {
    color: $white;
  }

  .BurglarAlarmHeader--address {
    color: $white;
  }
  .BurglarAlarmHeader--disarmed {
    color: $white;
  }
  .BurglarAlarmHeader--iconAlarmCanceled {
    fill: $white;
  }

  .AlarmConfirmationMessage--cancel,
  .MessageAlertStatus--cancel {
    background: $moonBlue;
  }

  .AlarmConfirmationMessage--verify,
  .MessageAlertStatus--verify,
  .ETA--confirmMessage,
  .ServiceMessage--confirmMessage,
  .FreeForm--confirmMessage,
  .ShareVideo--confirmMessage,
  .Responding--confirmMessage {
    background: $ADTBlue;
  }

  .AlarmConfirmation--root {
    background: $white;
    border-bottom: 1px solid $iceGrey;
  }

  .Timer--addTime,
  .Timer--counter {
    background-color: $white;
    color: $midnightBlue;
  }
  .Timer--addTime {
    border-bottom: 1px solid $iceGrey;
  }

  .HelpfulLinks--nav {
    background-color: $ADTBlue;
  }

  .Input--button svg {
    fill: $ADTBlue;
  }

  .AlarmConfirmation--verify {
    @include customButton($midnightBlue, null, null);
  }

  .AlarmConfirmation--cancel {
    @include customButton($moonBlue, null, null);
  }

  .Timer--addTimeButton {
    @include customButton($moonBlue, null, null);
  }
}

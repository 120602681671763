@import '../../styles/theme';
.MessageOwner--root {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.MessageOwner--timeline {
  display: flex;
  justify-content: flex-start;
}

.MessageOwner--messageline {
  display: flex;
  justify-content: flex-end;
}

.MessageOwner--contactIcon {
  border: 0px;
}

.MessageOwner--owner {
  align-items: flex-end;
}

.MessageOwner--span {
  width: 2rem;
  margin: 0 .5rem;
}

.MessageOwner--icon {
  position: relative;
  width: pxToRem(31px);
  height: pxToRem(31px);
  margin: 0 .5rem;
  align-self: flex-end;
  img {
    width: pxToRem(31px);
    height: pxToRem(33px);
  }
}

.MessageOwner--message {
  background-color: $moonBlue;
  border-radius: 0.6rem;
  font-size: pxToRem(14px);
  padding: 0.5rem 1rem;
  max-width: 75vw;
  overflow: inherit;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.MessageOwner--time {
  font-size: pxToRem(11px);
  padding: pxToRem(3px) pxToRem(6px) pxToRem(3px) pxToRem(8px);
}

.MessageOwner--messageline .MessageOwner-textWrapper {
  font-size: pxToRem(10px);
}

@media screen and (min-width: 1025px) {
  .MessageOwner--message {
    max-width: 675px;
  }
}

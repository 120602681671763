@import '../../styles/theme';
.Timer--addTime {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $ctaBlue;
  font-size: pxToRem(12px);
  line-height: pxToRem(16px);
  text-transform: uppercase;
  position: relative;
  min-height: 45px;
  .Timer--addTimeButton {
    color: $white;
    font-weight: bold;
  }
}

.Timer--counter {
  display: flex;
  justify-content: center;
  background-color: $ctaBlue;
  font-size: pxToRem(12px);
  line-height: pxToRem(16px);
  padding: pxToRem(3px) 0px;
}

.Timer--message span {
  font-weight: bold;
}

.Timer--addTimeButton {
  height: pxToRem(28px);
  position: absolute;
  right: pxToRem(8px);
  top: pxToRem(8px);
}

@media only screen and (max-width:321px) {
  .Timer--addTime .Timer--message {
    margin-right: pxToRem(70px);
  }
}

@import '../../styles/theme';
.Media--root {
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

.Media--background {
  position: fixed;
  height: 100vh;
}

.Media--close {
  width: 20px;
  height: 20px;
  margin: 20px;
  cursor: pointer;
  z-index: 999;
  &:before {
    content: '\00d7';
    font-size: 40px;
    color: $grey2;
  }
}

.Media--player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.Media--liveview,
.Media--clip {
  align-self: center;
}

@import '../../styles/theme.scss';

.Wall--root {
  overflow: auto;
  z-index: 5;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; /* has to be scroll, not auto */
}

.Wall--wallOverflow {
  outline: 0px solid red;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  flex-grow: 1;
}

.Wall--messageContent {
  outline: 0px solid red;
}

.Wall--unreadMessages {
  outline: 3px solid red;
}

.Wall--newMessages {
  position: fixed;
  bottom: pxToRem(130px);
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

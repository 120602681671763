@import '../../styles/theme';
.FreeForm--root {
  display: flex;
  max-width: 24rem;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(5px) pxToRem(14px);
}

.FreeForm--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0 .4rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}


.FreeForm--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(10px);
  background-color: $ctaBlue;
}

.FreeForm--confirmHeader {
  text-align: left;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  padding-bottom: 0.8rem;
  padding-left: pxToRem(3px);
}

.FreeForm--buttons {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  padding: pxToRem(5px) pxToRem(0px);
  color: $white;
}

.FreeForm--root .b {
  font-weight: bold;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  width: 100%;
  margin-top: 10px;
}

.FreeForm--eyeMessage {
  padding: pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(54px);
}

.FreeForm--row {
  padding-top: pxToRem(10px);
}

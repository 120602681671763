@import '../../styles/theme';
.SessionExpiredPage--root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  left: 0; right: 0; bottom: 0;
  z-index: 10;
}

img {
  width: pxToRem(160px);
  height: pxToRem(74px);
  text-align: center;
}

.SessionExpiredPage--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  margin-top: pxToRem(10px);
  padding-top: pxToRem(5px);
}

.SessionExpiredPage--title {
  font-size: pxToRem(16px);
  line-height: pxToRem(22px);
  font-weight: bold;
  text-align: center;
  color: $grey1;
}

.SessionExpiredPage--title a {
  color: $grey1;
  text-decoration: underline;
}
.SessionExpiredPage--title {
  padding: 0px 0px pxToRem(30px);
}

.SessionExpiredPage--helpfulLinks {
  width: 100%;
  position: absolute;
  bottom: pxToRem(0px);
}

@media screen and (min-width: 1025px) {
  .SessionExpiredPage--helpfulLinks {
    max-width: 768px;
  }
}

@import '../../styles/theme';
.Reconnecting--root {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  left: 0; right: 0; bottom: 0;
  z-index: 10;
}

.Reconnecting--background {
  background-color: $white;
  opacity: 0.9;
  position: absolute;
  height: 100vh;
  left: 0; right: 0; bottom: 0;
  z-index: 9;
}

img {
  width: pxToRem(92px);
  height: pxToRem(89px);
  text-align: center;
}

.Reconnecting--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: pxToRem(10px);
  padding-top: pxToRem(5px);
}

.Reconnecting--title,
.Reconnecting--message {
  font-size: pxToRem(16px);
  line-height: pxToRem(22px);
  font-weight: bold;
  color: $grey1;
}

.Reconnecting--title {
  padding: 0px 0px pxToRem(20px);
}

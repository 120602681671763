.Chat--root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.Chat--keyboard {
  position: absolute;
  bottom: 0;
}


@media screen and (min-width: 1025px) {
  .Chat--keyboard {
    max-width: 768px;
    max-height: 1024px;
    box-shadow: 1px 1px 5px black;
  }
}

@import '../../styles/theme';
.PersonsActions--root {
  padding: pxToRem(5px) pxToRem(15px) pxToRem(5px) pxToRem(8px);
}

.PersonsActions--timeline {
  text-align: center;
}

.PersonsActions--time {
  font-size: 11px;
  line-height: 15px;
}

.PersonsActions--messageLine {
  display: flex;
  flex-direction: column;
}


@import '../../styles/theme';
.MessageBot--root {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  z-index: -1;
}

.MessageBot--timeline {
  display: flex;
  justify-content: flex-start;
}

.MessageBot--messageline {
  display: flex;
}

.MessageBot--join {
  justify-content: center;
  padding: 1rem;
  color: $grey1;
}

.MessageBot--contactIcon {
  border: 0px;
}

.MessageBot--owner {
  align-items: flex-end;
}

.MessageBot--span {
  width: 2rem;
  margin: 0 0.5rem;
}

.MessageBot--icon {
  position: relative;
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0 0.5rem;
  margin-top: 0.3rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.MessageBot--iconCommercial {
  position: relative;
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0 0.5rem;
  margin-top: 0.3rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}


.MessageBot--content {
  background-color: $grey5;
  border-radius: 0.6rem;
  max-width: 18rem;
}

.MessageBot--message {
  padding: 0.5rem 1rem;
  font-size: pxToRem(14px);
  overflow-wrap: break-word;
}

.MessageBot--poster {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.MessageBot--image {
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  width: 100%;
  min-width: pxToRem(216px);
  background: #000;
  height: 88px;
  background-size: cover;
}

.MessageBot--audioContainer {
  height: 45px;
  background: #444444;
}

.MessageBot--audio {
  width: 60px;
  height: 100%;
  fill: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -19px;
  margin-left: -25px;
}

.MessageBot--play {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.MessageBot--circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid $white;
}

.MessageBot--triangle {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -6px;
  border-style: solid;
  border-width: 10px 0 10px 17.3px;
  border-color: transparent transparent transparent #ffffff;
}
.MessageBot--background {
  background: $white;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  width: 40px;
  height: 40px;
}

.MessageBot--time {
  font-size: pxToRem(11px);
  padding: pxToRem(3px) pxToRem(3px) pxToRem(3px) pxToRem(8px);
}

.MessageBot--join {
  color: $grey1;
  display: flex;
  justify-content: center;
  padding: 0.3rem 0;
}

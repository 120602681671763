@import '../../styles/theme';
.TermsAndConditions--root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

img {
  width: pxToRem(160px);
  height: pxToRem(74px);
  text-align: center;
}

.TermsAndConditions--middle {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.TermsAndConditions--contentWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.TermsAndConditions--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: pxToRem(30px) auto;
  padding: pxToRem(15px) pxToRem(15px);
  background-color: #fff;
}

.TermsAndConditions--title,
.TermsAndConditions--message,
.TermsAndConditions--terms {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
}

.TermsAndConditions--title {
  font-weight: bold;
}

.TermsAndConditions--message {
  padding-top: pxToRem(15px);
}

.TermsAndConditions--button {
  padding: pxToRem(20px) 0 pxToRem(10px) 0;
  width: pxToRem(179px);
}

.TermsAndConditions--button .TermsAndConditions--buttonBold {
  font-weight: bold;
  padding: pxToRem(10px) 0;
}

.TermsAndConditions--helpfulLinks {
  width: 100%;
}

@media screen and (max-height: 320px) {
  .TermsAndConditions--content {
    margin: 5px;
  }
}

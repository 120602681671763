@import '../../styles/theme';
.ETA--root {
  align: left;
  display: flex;
  max-width: 26rem;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(5px) pxToRem(14px);
}

.ETA--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0 .4rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.ETA--iconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0 .4rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}

.ETA--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(10px);
  background-color: $ctaBlue;
}

.ETA--confirmHeader {
  text-align: left;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  padding-bottom: 0.8rem;
  padding-left: pxToRem(3px);
}

.ETA--buttons {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  padding: pxToRem(5px) pxToRem(0px);
  color: $white;
}

.ETA--root .b {
  font-weight: bold;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  width: 100%;
}

.ETA--eyeMessage {
  padding: pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(54px);
}

.ETA--row {
  padding-top: pxToRem(10px);
}

.ETA--respond {
  max-width: 720px;
  width: 200%;
  margin: 0 auto;
  padding-top: pxToRem(10px);
}

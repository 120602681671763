@import '../../styles/theme';
.MessagePerson--root {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding-left: pxToRem(12px);
}

.MessagePerson--timeline {
  display: flex;
  justify-content: flex-start;
}

.MessagePerson--messageline {
  display: flex;
}

.MessagePerson--join {
  justify-content: center;
  padding: 1rem;
  color: $grey1;
}

.MessagePerson--contactIcon {
  border: 0px;
}

.MessagePerson--owner {
  align-items: flex-end;
}

.MessagePerson--span {
  width: 2rem;
  margin: 0 .5rem;
}

.MessagePerson--icon {
  display: flex;
  width: pxToRem(31px);
  height: pxToRem(31px);
  align-self: flex-end;
  margin: 0 .5rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.MessagePerson--message {
  background-color: $grey5;
  border-radius: 0.6rem;
  font-size: pxToRem(14px);
  padding: 0.5rem 1rem;
  max-width: 75vw;
  white-space: pre-wrap;
  overflow: inherit;
  overflow-wrap: break-word;
}

.MessagePerson--time {
  font-size: pxToRem(11px);
  padding: pxToRem(3px) pxToRem(3px) pxToRem(3px) pxToRem(8px);
}
.MessagePerson--messageline .MessagePerson-textWrapper {
  font-size: pxToRem(10px);
}

@media screen and (min-width: 1025px) {
  .MessagePerson--message {
    max-width: 675px;
  }
}


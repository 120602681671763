@import '../../styles/theme';
.HelpfulLinks--nav {
  font-size: pxToRem(11px);
  line-height: pxToRem(15px);
  min-height: pxToRem(48px);
  background-color: $grey2;
  .HelpfulLinks--item {
    cursor: pointer;
  }
  .HelpfulLinks--link {
    padding: pxToRem(17px) 0;
    flex: auto;
    text-align: center;
    color: $white;
  }
  .HelpfulLinks--link:hover {
    background-color: $grey1;
    color: $white;
  }
}

$mainFontFize: 12px;
$headerHeight: 200px;
$bottomHeight: 200px;

$contactCircleSize: 31px;
$messageContentWidth: pxToRem(215px);

$grey1: #5e6366;
$grey2: #898e91;
$grey3: #b7bdc0;
$grey4: #d4d9dd;
$grey5: #e7eaec;
$grey6: #a1a1a1;
$greyBG: #f3f5f6;
$iceGrey: #ededed;
$primaryBlack: #242629;
$ctaBlue: #1775ab;
$moonBlue: #1f99e0;
$ADTBlue: #0061aa;
$midnightBlue: #102955;
$green: #50bda7;
$coral: #e76655;
$white: #ffffff;

$colors: (
  grey1: $grey1,
  grey2: $grey2,
  grey3: $grey3,
  grey4: $grey4,
  grey5: $grey5,
  grey6: $grey6,
  greyBG: $greyBG,
  primaryBlack: $primaryBlack,
  ctaBlue: $ctaBlue,
  green: $green,
  coral: $coral,
  white: $white,
  moonBlue: $moonBlue,
  ADTBlue: $ADTBlue,
  midnightBlue: $midnightBlue,
);

@mixin customButton($color, $border, $borderColor) {
  background-color: $color;
  @if $border {
    border: $border;
    @if $borderColor {
      border-color: $borderColor;
    }
  } @else {
    border: 0;
  }
  &:hover {
    background-color: scale-color($color, $lightness: -15%);
    @if $borderColor {
      border-color: scale-color($borderColor, $lightness: -15%);
    } @else {
      border-color: scale-color($color, $lightness: -15%);
    }
  }
}

@mixin makeColors {
  @each $color-name, $color-value in $colors {
    .text-#{$color-name} {
      color: $color-value;
    }
    .bg-#{$color-name} {
      background-color: $color-value;
    }
    .btn-#{$color-name} {
      @include customButton($color-value, null, null);
    }
  }
}

@function pxToRem($px) {
  @return ($px / $mainFontFize) * 1rem;
}

@import '../../styles/theme';
.AlarmConfirmationMessage--root {
  display: flex;
  max-width: 24rem;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(5px) pxToRem(12px);
}

.AlarmConfirmationMessage--icon {
  align-self: flex-end;
  width: 31px;
  height: 32px;
  margin: 0 .5rem;
  img {
    width: 31px;
    height: 32px;
  }
}

.AlarmConfirmationMessage--verify {
  background-color: $coral;
}

.AlarmConfirmationMessage--cancel {
  background-color: $green;
}

.AlarmConfirmationMessage--confirmMessage {
  border-radius: 0.6rem;
  padding: pxToRem(10px);
}

.AlarmConfirmationMessage--confirmHeader {
  display: flex;
  align-items: center;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  padding-bottom: pxToRem(16px);
}

.AlarmConfirmationMessage--confirmIcon img {
  margin-left: pxToRem(3px);
  height: pxToRem(32px);
  width: pxToRem(24px);
}

.AlarmConfirmationMessage--buttons {
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  padding: pxToRem(5px) pxToRem(0px);
  color: $white;
}

.AlarmConfirmationMessage--button span {
  font-weight: bold;
}

.AlarmConfirmationMessage--eyeMessage {
  padding: pxToRem(0px) pxToRem(0px) pxToRem(5px) pxToRem(55px);
}

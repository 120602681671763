@import '../../styles/theme';
.Preloader--root {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.Preloader--background {
  background-color: $white;
  opacity: 0.9;
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.Preloader--image {
  width: pxToRem(50px);
  height: pxToRem(50px);
  text-align: center;
}
.Preloader--spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .Preloader--path {
    stroke: #0061aa;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@import '../../styles/theme';
.MessageAlertStatus--root {
  display: flex;
  padding: pxToRem(15px) pxToRem(15px) pxToRem(12px) pxToRem(14px);
}

.MessageAlertStatus--icon img {
  height: pxToRem(32px);
  width: pxToRem(24px);
}
.MessageAlertStatus--verify {
  background-color: $coral;
}
.MessageAlertStatus--cancel {
  background-color: $green;
}

.MessageAlertStatus--botIcon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0 .4rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(33px);
  }
}

.MessageAlertStatus--botIconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0 .4rem;
  img {
    width: pxToRem(31px);
    height: pxToRem(16px);
  }
}

.MessageAlertStatus--content {
  max-width: $messageContentWidth;
  display: flex;
  font-size: pxToRem(14px);
  line-height: pxToRem(19px);
  color: $white;
  align-items: center;
  border-radius: pxToRem(10px);
  padding: pxToRem(12px);
}

.MessageAlertStatus--message {
  width: $messageContentWidth - pxToRem(70px);
  padding: 0px pxToRem(10px);
  text-align: left;
}

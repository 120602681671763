@import '../../styles/theme';
.ChatLogo--icon {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(32px);
  margin: 0px 5px 0px 3px;
  img {
    width: pxToRem(31px);
    height: pxToRem(32px);
  }
}

.ChatLogo--iconCommercial {
  align-self: flex-end;
  width: pxToRem(31px);
  height: pxToRem(16px);
  margin: 0px 5px 3px 3px;
  img {
    width: pxToRem(32px);
    height: pxToRem(14px);
  }
}
